import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Phaser from 'phaser';
import '../css/React.css';
import Menu from './Menu';

// scenes
import WelcomeScene from './scenes/WelcomeScene';
import MainMenuScene from './scenes/MainMenuScene';
import SettingsScene from './scenes/SettingsScene';

import musicButton from "../assets/buttons/MusicButton.png";

const Welcome = () => {
  const gameRef = useRef(null);
  const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsPortrait(window.innerHeight > window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Initialize or re-initialize the game when orientation changes
    if (gameRef.current) {
      gameRef.current.destroy(true);
      gameRef.current = null;
    }


    const config = {
      type: Phaser.AUTO,
      parent: 'phaser-game',
      width: 800,
      height: 800,
      physics: {
        default: 'arcade',
        arcade: {
            gravity: { y: 200 },
            debug: false
        },
    },
      scale: {
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_BOTH,
      },
      scene: [
        WelcomeScene,
        MainMenuScene,
        SettingsScene
      ],
    };

    gameRef.current = new Phaser.Game(config);
    gameRef.current.scene.start('WelcomeScene', { navigate });

    return () => {
      window.removeEventListener('resize', handleResize);
      if (gameRef.current) {
        gameRef.current.destroy(true);
        gameRef.current = null;
      }
    };
  }, [isPortrait]);


  return (
    <div className="welcome-page">
      <Menu />
      <div id="phaser-game" />
    </div>
  );
};

export default Welcome;

import BaseScene from "./BaseScene"
import playButton from "../../assets/buttons/PlayButton.png";
import statsButton from "../../assets/buttons/StatsButton.png";
import profileButton from "../../assets/buttons/ProfileButton.png";
import settingsButton from "../../assets/buttons/SettingsButton.png";


class MainMenuScene extends BaseScene {
    constructor() {
        super('SettingsScene');
    }

    init(data) {
        this.registry.set('debug', false);
        // this.registry.set('debug', true);
        this.navigate = data.navigate;
    }

    preload() {
        this.load.image('playButton', playButton);
        this.load.image('statsButton', statsButton);
        this.load.image('profileButton', profileButton);
        this.load.image('settingsButton', settingsButton);

        this.load.audio('ready', '/ready.mp3');
        this.load.audio('swoosh', '/swoosh.mp3');
        this.load.audio('swooshBack', '/swooshBack.mp3');

    }

    create() {
        const debug = this.registry.get('debug');
        if (debug) {
            this.graphicsText();
            this.add.text(this.sys.game.config.width * 0.5 ,0,"Debug mode",{color: 'Red'})
        }

        const pageName = 'Main Menu'

        this.setupPage(pageName)

        this.cameras.main.setBackgroundColor('#ffffff');

        const buttonsData = [
            { key: 'playButton', action: () => { this.navigate('/game'); } },
            { key: 'statsButton', action: () => { this.navigate('/stats', '_blank'); }  },
            { key: 'profileButton', action: () => { this.scene.start('ProfileScene'); }  },
            { key: 'settingsButton', action: () => { this.scene.start('SettingsScene'); } }
            // { key: 'playButton', action: () => { window.location.href = 'https://fynschool.com/game'; } },
            // { key: 'statsButton', action: () => { window.open('https://example.com/settings', '_blank'); } },
        ];

        // X position based on the rule of thirds
        const xPosition = 800 / 3;

        // Y positions spaced out equally, assuming a height of 800 and some padding
        const startY = 150; // First button's Y position
        const padding = 150; // Spacing between buttons

        // Create an empty list to hold the buttons
        const buttons = [];

        // Loop through the button data to create and position buttons
        buttonsData.forEach((buttonData, index) => {
            const yPosition = startY + index * padding;
            const button = this.createButton(xPosition, yPosition, buttonData.key, null, buttonData.action, 1);
            buttons.push(button);
        });

        // Apply the waterfall effect with delay for each button
        buttons.forEach((button, index) => {
            this.time.delayedCall(500 * index, () => {
                this.tweens.add({
                    targets: button,
                    alpha: 1, // Fade in to full visibility
                    duration: 1000, // 1 second fade-in duration
                    ease: 'Power1'
                });
            });
        });

    }
}

export default MainMenuScene;
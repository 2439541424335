import BaseScene from "./BaseScene";
import startButton from "../../assets/buttons/StartButton.png";
import logo from "./Logo.png";



class WelcomeScene extends BaseScene {
    constructor() {
        super('WelcomeScene');
    }

    init(data) {
        this.registry.set('debug', false);
        // this.registry.set('debug', true);
        this.navigate = data.navigate;
    }

    preload() {
        this.load.image('logo', logo);
        this.load.image('startButton', startButton);

        this.load.audio('islandFlex', '/IslandFlex.mp3');
        this.load.audio('ready', '/selected.wav');
        this.load.audio('swoosh', '/swoosh.wav');
        this.load.audio('swooshBack', '/swooshBack.wav');
    }

    create() {
        const debug = this.registry.get('debug');
        if (debug) {
            this.graphicsText();
            this.add.text(this.sys.game.config.width * 0.5 ,0,"Debug mode",{color: 'Red'})
        }

        // page set up
        this.cameras.main.setBackgroundColor('#ffffff');

        const music = this.sound.add('islandFlex');
                music.play({
                    loop: true,
                    volume: 0.05
                });

        const logo = this.add.image(400,400, 'logo').setOrigin(0.5,0.5);

        this.tweens.add({
            targets: logo,
            y: 800 / 3,  // move the logo up to 800/3
            duration: 2000,  // 2 seconds
            ease: 'Cubic.easeInOut',  // optional easing for a smoother effect
        });

        this.createButton(400, 550, 'startButton', null, () => {
        this.scene.start('MainMenuScene', { navigate: this.navigate });
        },1);
    }
}

export default WelcomeScene;
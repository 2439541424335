// src/pages/Menu.js
import React from 'react';

export default function Menu() {
  return (
    <div>
      <nav>
        {/* Navigation elements go here */}
        <ul>
          <li><a href="/welcome">Home</a></li>
          <li><a href="/">Learn</a></li>
          <li><a href="/stats">Stats</a></li>
          <li><a href="/profiles">Profile</a></li>
        </ul>
      </nav>

    </div>
  );
}

import Phaser from "phaser";


class BaseScene extends Phaser.Scene {
    constructor(key) {
        super(key);
    }

    init() {
        this.registry.set('debug', false);
        // const isPortrait = this.sys.game.config.width < this.sys.game.config.height;
        // if (isPortrait) {
        //     this.registry.set('isPortrait', true);
        // }
    }

    graphicsText() {
        // For debugging, add a rectangle to show the game bounds
        const graphics = this.add.graphics();
        graphics.lineStyle(2, 0xff0000, 1);
        graphics.strokeRect(0, 0, this.scale.width, this.scale.height);

        // Display the game dimensions
        this.add.text(10, 10, `Width: ${this.scale.width}, Height: ${this.scale.height}`, {
        fontSize: '16px',
        fill: '#fff',
        });
    }

    setupPage(pageName) {

        this.add.text(10, 100, pageName);

    }

    mainText(x, y, text, color, fontSize = '32px', fontFamily = 'leckerli', useBold = false, letterSpacing = 0) {
        // Function to lighten a hex color
        const lightenColor = (color, amount) => {
            let usePound = false;
            if (color[0] === "#") {
                color = color.slice(1);
                usePound = true;
            }

            let num = parseInt(color, 16);
            let r = (num >> 16) + amount;
            if (r > 255) r = 255;
            else if (r < 0) r = 0;

            let b = ((num >> 8) & 0x00FF) + amount;
            if (b > 255) b = 255;
            else if (b < 0) b = 0;

            let g = (num & 0x0000FF) + amount;
            if (g > 255) g = 255;
            else if (g < 0) g = 0;

            return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
        };

        // Apply letter spacing manually by adjusting character positions
        if (letterSpacing > 0) {
            let spacedText = '';
            for (let i = 0; i < text.length; i++) {
                spacedText += text[i];
                if (i < text.length - 1) {
                    spacedText += `\u200A\u200A`; // Add a thin space (unicode character) between letters
                }
            }
            text = spacedText;
        }

        // Adjust font style for bold if needed
        const fontStyle = useBold ? 'bold' : 'normal';

        // Create the inner shadow text (lighter color based on input color, shifted up-left)
        const innerShadowColor = lightenColor(color, 10);
        const innerShadow = this.add.text(x + 11, y + 6, text, {
            fontFamily: fontFamily,
            fontSize: fontSize,
            fontStyle: fontStyle,
            color: '#000000',
            align: 'center',
        }).setAlpha(0.08); // Semi-transparent for inner shadow

        // Create the outer shadow text (lighter color, shifted down-right)
        const outerShadow = this.add.text(x - 1, y - 3, text, {
            fontFamily: fontFamily,
            fontSize: fontSize,
            fontStyle: fontStyle,
            color: innerShadowColor, // Lighter color for the outer shadow
            align: 'center',
        }).setAlpha(0.4); // Semi-transparent for outer shadow

        // Create the main text
        const mainText = this.add.text(x, y, text, {
            fontFamily: fontFamily,
            fontSize: fontSize,
            fontStyle: fontStyle,
            color: color,
            align: 'center'
        });

        // Animate the main text to gently move like a cloud
        this.tweens.add({
            targets: [innerShadow, outerShadow, mainText],
            x: `+=${10 + Math.random() * 10}`, // Randomize x movement to make it organic
            y: `+=${5 + Math.random() * 5}`, // Add slight y movement to mimic clouds
            yoyo: true,
            duration: 4000 + Math.random() * 2000, // Randomize duration for organic effect
            ease: 'Sine.easeInOut',
            repeat: -1
        });
        return mainText;
    }

    swayObject(target, initialAngle = 0) {
        // Set the initial angle of the target
        target.setAngle(initialAngle);

        // Animate the target to sway gently, anchored at its origin
        this.tweens.add({
            targets: target,
            angle: { from: initialAngle - 2, to: initialAngle + 2 }, // Sway angle between initialAngle - 2 and initialAngle + 2 degrees
            duration: 3000 + Math.random() * 2000, // Randomize duration for organic effect
            yoyo: true,
            ease: 'Sine.easeInOut',
            repeat: -1
        });
    }

    calculateButtonScale(buttonWidth, buttonHeight) {
        // Define the target size as a percentage of screen dimensions
        const targetWidthPercentage = 0.065; // Example: 10% of screen width
        const targetHeightPercentage = 0.15; // Example: 20% of screen height, adjust as needed

        // Calculate target width and height in pixels
        const targetWidth = this.sys.game.config.width * targetWidthPercentage;
        const targetHeight = this.sys.game.config.height * targetHeightPercentage;

        // Calculate scale factors for width and height
        const scaleWidth = targetWidth / buttonWidth;
        const scaleHeight = targetHeight / buttonHeight;

        // Use the smaller of the two scales to ensure the button fits well within both dimensions
        const scale = Math.max(scaleWidth, scaleHeight);

        return scale; // This scale factor can be applied to both width and height of the button
    }

    createButton(x, y, texture, hoverTexture, action, scaleMultiplier = 1, unavailable = false) {
        const button = this.add.image(x, y, texture).setInteractive();

        // Calculate the scale for visibility across different resolutions
        const baseScale = this.calculateButtonScale(button.width, button.height) * scaleMultiplier;
        button.setScale(baseScale);
        button.setAlpha(0); // Start with full transparency (invisible)

        // Define hover effect values
        const hoverScaleFactor = 1.1; // Increase scale by 10% on hover
        const hoverAlpha = 1; // Reduced alpha for hover state

        if (!unavailable) {
            button.on('pointerover', () => {
                if (hoverTexture) {
                    button.setTexture(hoverTexture);
                } else {
                    this.sound.play('swoosh', { volume: 0.1 });
                    this.tweens.add({
                        targets: button,
                        scale: baseScale * hoverScaleFactor, // Apply hover scale
                        alpha: hoverAlpha,
                        duration: 200,
                        ease: 'Power1'
                    });
                }
            });

            button.on('pointerout', () => {
                if (hoverTexture) {
                    button.setTexture(texture);
                }
                this.sound.play('swooshBack', { volume: 0.1 });
                this.tweens.add({
                    targets: button,
                    scale: baseScale, // Revert to original (base) scale
                    alpha: 1, // Full opacity
                    duration: 200,
                    ease: 'Power1'
                });
            });

            button.on('pointerdown', () => {
                this.sound.play('ready', { volume: 0.2 });
                action(); // Execute the action passed to the button
            });
        } else {
            button.disableInteractive();
            button.setAlpha(0.3); // Make button half transparent
        }
        button.setDepth(1000);

        // After the initial 2-second delay, fade in the button
        this.time.delayedCall(2000, () => {
            this.tweens.add({
                targets: button,
                alpha: 1, // Fade in to full visibility
                duration: 1000, // 1 second fade-in duration
                ease: 'Power1'
            });
        });

        return button;
    }


}

export default BaseScene;
